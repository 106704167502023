import Vue from 'vue'
import App from './App.vue'

import store from './store'

import VueRouter from 'vue-router'
import routes from './routes'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Notifications from 'vue-notification'
// import VueMoment from 'vue-moment'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import boot from './boot'
import './filters'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications)
// Vue.use(VueMoment)

Vue.use(VueRouter)
const router = new VueRouter({ mode: 'history', routes })

// 시작시 api서버 정보 및 파이어베이스 초기화.
const app = async () => {
  await boot({
    Vue,
    store,
  })

  let v = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
  window.app = v
}

app()
