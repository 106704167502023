import Vue from 'vue'
import moment from 'moment'

Vue.filter('money', val => {
  if (!val) return '0'
  return new Number(val).toLocaleString()
  //return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.filter('date', val => {
  if (!val) return null
  return moment(val).format('YYYY/MM/DD HH:mm:ss')
  //return new Date(val).toLocaleString('ko-KR', { dateStyle: 'medium', timeStyle: 'medium', hour12: false })
})

Vue.filter('date_s', val => {
  if (!val) return null
  return moment(val).format('YY/MM/DD HH:mm')
  //return new Date(val).toLocaleString('ko-KR', { dateStyle: 'medium', timeStyle: 'medium', hour12: false })
})

Vue.filter('date_d', val => {
  if (!val) return null
  return moment(val).format('YYYY/MM/DD')
  //return new Date(val).toLocaleString('ko-KR', { dateStyle: 'medium', timeStyle: 'medium', hour12: false })
})

Vue.filter('time', val => {
  if (!val) return null
  return moment(val).format('HH:mm:ss')
  //return new Date(val).toLocaleString('ko-KR', { dateStyle: 'medium', timeStyle: 'medium', hour12: false })
})

Vue.filter('time_m', val => {
  if (!val) return null
  return moment(val).format('HH:mm')
  //return new Date(val).toLocaleString('ko-KR', { dateStyle: 'medium', timeStyle: 'medium', hour12: false })
})
