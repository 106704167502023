import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth' // eslint-disable-line

export default async ({ Vue, store }) => {
  Vue.prototype.$axios = axios
  // something to do
  await axios({ method: 'put', url: '/api/www' })
    .then((res) => {
      const pub = res.data
      //
      if (pub) {
        pub.bgClass = ''
        pub.topSrc = ''
        switch (pub.pub) {
          case 'MR':
            break
          case 'AS':
            break
          case 'RA':
            break
          case 'KH':
            break
          default:
            break
        }
        pub.url = window.location.origin
        Vue.prototype.$PUB = pub
        store.commit('SET_CFG', pub)

        if (pub.firebase) {
          firebase.initializeApp(pub.firebase)
          Vue.prototype.$firebase = firebase
          console.log('[Success] firebase connect')
        }
      } else {
        console.error(`[error] can't read PUB`)
      }
    })
    .catch(function(error) {
      if (error.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        alert('서버접속 에러')
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log(error.request)
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        console.log('Error', error.message)
      }
      console.log(error.config)
    })
}
