const routes = [
  {
    path: '/',
    component: () => import('../layout/layout.vue'),
    children: [
      { path: '', name: 'Home', component: () => import('../pages/home.vue') },
      { path: '/login', name: 'Login', component: () => import('../pages/login.vue') },
      { path: '/join', name: 'Join', component: () => import('../pages/join.vue') },
      { path: '/about', name: 'About', component: () => import('../pages/about.vue') },
      { path: '/notice', name: 'Notice', component: () => import('../pages/notice.vue') },
      { path: '/board', name: 'Board', component: () => import('../pages/board.vue') },
      { path: '/faq', name: 'FAQ', component: () => import('../pages/faq.vue') },
      { path: '/qna', name: 'Qna', component: () => import('../pages/qna.vue') },
      { path: '/privacy', name: 'Privacy', component: () => import('../pages/privacy.vue') },
      { path: '/provision', name: 'Provision', component: () => import('../pages/provision.vue') },
      { path: '/iam', name: 'MyPage', component: () => import('../pages/iam.vue') },
      { path: '/_debug', name: 'Debug', component: () => import('../pages/_debug.vue') }
    ]
  },
  { path: '/login3rd', name: 'login', component: () => import('../pages/login3rd.vue') },
  { path: '/cert', name: 'Cert', component: () => import('../pages/cert.vue') },
  { path: '/kcert', name: 'Kcert', component: () => import('../pages/kcert.vue') },
  { path: '/kakao', name: 'Kakao', component: () => import('../pages/kakao.vue') },
  { path: '/naver', name: 'Naver', component: () => import('../pages/naver.vue') },
  { path: '/telegram', name: 'Telegram', component: () => import('../pages/telegram.vue') },
  { path: '/bootpay', name: 'Bootpay', component: () => import('../pages/bootpay.vue') },

  { path: '*', component: () => import('../pages/Error404.vue') }
]

export default routes
