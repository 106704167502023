import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from 'vuex-persistedstate'

import * as Api from '../api'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {},
  //plugins: [createPersistedState()],
  state: {
    cfg: null,
    user: null,
    ws: null,
    ws2: null
  },
  getters: {
    isLogin: state => !!state.user,
    User: state => state.user,
    CFG: state => state.cfg,
    wsOn: state => !!state.ws,
    ws_id: state => state.ws,
    ws2: state => state.ws2
  },
  mutations: {
    SET_CFG(state, data) {
      state.cfg = data
    },
    SET_LOGOUT(state) {
      this._vm.$firebase.auth().signOut()
      state.user = null
      localStorage.removeItem('pubtoken')
    },
    SET_USER(state, payload) {
      let user = payload
      state.user = user
    },
    SET_WS(state, payload) {
      console.log('-> SET_WS')
      state.ws = payload
    },
    DEL_WS(state) {
      console.log('-> DEL_WS')
      state.ws = null
    },
    SET_WS2(state, payload) {
      console.log('-> SET_WS2')
      state.ws2 = payload
    },
    SET_TEST(state, payload) {
      console.log('-> SET_TEST', payload)
    }
  },
  actions: {
    infoUser({ commit }) {
      const pubtoken = localStorage.getItem('pubtoken')
      if (!pubtoken) return
      Api.user
        .info()
        .then(res => commit('SET_USER', res))
        .catch(err => {
          console.log('Api.user.info', err)
          localStorage.removeItem('pubtoken')
        })
    },
    setLogout({ commit }) {
      const pubtoken = localStorage.getItem('pubtoken')
      if (!pubtoken) return commit('SET_LOGOUT')
      Api.auth
        .logout()
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          commit('SET_LOGOUT')
        })
    }
  },
  strict: process.env.DEV
})

export default store
