import axios from 'axios'
import store from '../store'

const DOMAIN = '/api'
const UNAUTHORIZED = 401
const EXPIRESESSION = 402
const onUnauthorized = () => {
  localStorage.removeItem('pubtoken')
  console.log('chk!! Logout')
  store.commit('SET_LOGOUT')
}
const onBlock = () => {
  localStorage.removeItem('pubtoken')
  window.location.href = 'http://google.com'
}

const request = (method, url, data) => {
  const pubtoken = localStorage.getItem('pubtoken')
  url = DOMAIN + url + '?_=' + Date.now()
  if (method == 'get' && typeof data == 'object') {
    for (let d in data) {
      url += `&${d}=${data[d]}`
    }
  }
  console.log(url)
  return axios({
    method,
    url,
    data,
    headers: {
      authorization: pubtoken
    }
  })
    .then(result => {
      //console.log(url, result.data) // for test
      return result.data
    })
    .catch(result => {
      const { status, data, statusText } = result.response
      if (status == UNAUTHORIZED || status == EXPIRESESSION) onUnauthorized()
      if (status === 406) return onBlock()
      let ret = data.error ? data : { error: statusText }
      console.log('err ::', ret)
      throw ret
    })
}

// export const setAuthInHeader = token => {
//   console.log('chk', token)
//   axios.defaults.headers.common['authorization'] = token ? `Bearer ${token}` : null
// }

export const www = {
  ping() {
    return request('get', '/ping')
  },
  www() {
    return request('put', '/www')
  }
}

export const auth = {
  login(obj) {
    return request('post', '/login', obj)
  },
  flogin(obj) {
    return request('post', '/flogin', obj)
  },
  tlogin(obj) {
    return request('post', '/tlogin', obj)
  },
  klogin(access_token) {
    return request('post', '/klogin', { access_token })
  },
  nlogin(obj) {
    return request('post', '/nlogin', obj)
  },
  logout() {
    return request('get', '/u/logout')
  },
  join(obj) {
    return request('post', '/join', obj)
  },
  joinfb(obj) {
    return request('post', '/joinfb', obj)
  },
  chkAccount(obj) {
    return request('get', `/chk/account`, obj)
  },
  chkNick(obj) {
    return request('get', `/chk/nick`, obj)
  },
  update_nick(obj) {
    return request('put', `/u/update_nick`, obj)
  },
  // ddbug fun
  dropout() {
    return request('put', `/u/dropout`)
  }
}

export const user = {
  info() {
    return request('get', '/u/info')
  },
  ping() {
    return request('get', `/u/ping`)
  }
}

export const kcert = {
  cert() {
    return request('post', '/u/kcert/cert')
  },
  verify() {
    return request('post', '/u/kcert/verify')
  }
}

export const board = {
  list(query) {
    return request('get', '/u/boards', query)
  },
  info(idx) {
    return request('get', `/u/board/${idx}`)
  },
  write(obj) {
    return request('post', `/u/board`, obj)
  },
  reply(obj) {
    return request('post', `/u/reply`, obj)
  }
}

export const sms = {
  info(idx) {
    return request('get', `/u/sms/${idx}`)
  },
  list(query) {
    return request('get', '/u/smss', query)
  },
  write(obj) {
    return request('post', `/u/sms`, obj)
  }
}

export const item = {
  shop(query) {
    return request('get', '/u/item/shop', query)
  },
  info(idx) {
    return request('get', `/u/item/shop/${idx}`)
  },
  my(query) {
    return request('get', '/u/item/my', query)
  },
  receipt(obj) {
    return request('post', `/u/item/receipt`, obj)
  }
}
